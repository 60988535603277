import * as React from 'react'

type Props = {
  children: React.ReactNode
  styleName?: string
}

export default function Title({ children, styleName }: Props) {
  return (
    <div className={`headline pb-2 ${styleName}`}>
      <h1 className="text-regal-blue text-xl font-medium xl:text-2xl">{children}</h1>
    </div>
  )
}
