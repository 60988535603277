import * as React from 'react'

type Props = {
  children: React.ReactNode
  styleName?: string
}

export default function Text({ children, styleName }: Props) {
  return <div className={`font-light xl:text-lg ${styleName}`}>{children}</div>
}
