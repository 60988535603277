import * as React from 'react'

type Props = {
  children: React.ReactNode
  styleName?: string
}

export default function Citation({ children, styleName }: Props) {
  return (
    <div className={`text-bronze italic font-normal md:text-md lg:text-lg xl:text-xl ${styleName}`}>
      &#8222;{children}&#8221;
    </div>
  )
}
