import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Loader } from 'components'

const Home = React.lazy(() => import('pages/Home')) // Startseite
const Services = React.lazy(() => import('pages/Services')) // Leistungen
const References = React.lazy(() => import('pages/References')) // Referenzen
const Funding = React.lazy(() => import('pages/Funding')) // Fördermittel
const Assessment = React.lazy(() => import('pages/Assessment')) // Gutachten
const Contact = React.lazy(() => import('pages/Contact')) // Kontakt

const Privacy = React.lazy(() => import('pages/Privacy')) // Datenschutz
const Imprint = React.lazy(() => import('pages/Imprint')) // Impressum

const LoadingIndicatorWithDelay = () => {
  const delay = 500 // 200ms
  const [showLoadingIndicator, setLoadingIndicatorVisibility] = React.useState<boolean>(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => setLoadingIndicatorVisibility(true), delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  if (showLoadingIndicator) {
    return <Loader />
  }

  return null
}

export default function AppContainer() {
  return (
    <article id="page" className="px-4 md:px-8 lg:px-12 xl:px-32">
      <React.Suspense fallback={<LoadingIndicatorWithDelay />}>
        <Switch>
          <Route exact path="/leistungen">
            <Services />
          </Route>
          <Route exact path="/referenzen">
            <References />
          </Route>
          <Route exact path="/foerdermittel">
            <Funding />
          </Route>
          <Route exact path="/gutachten">
            <Assessment />
          </Route>
          <Route exact path="/kontakt">
            <Contact />
          </Route>
          <Route exact path="/datenschutz">
            <Privacy />
          </Route>
          <Route exact path="/impressum">
            <Imprint />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/*">
            <Redirect push to="/" />
          </Route>
        </Switch>
      </React.Suspense>
    </article>
  )
}
