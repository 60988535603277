import * as React from 'react'

type Props = {
  size: number
  sizeMd?: number
  sizeLg?: number
  sizeXl?: number
}

export default function Spacer({ size, sizeMd, sizeLg, sizeXl }: Props) {
  return (
    <div
      className={`block h-${size} ${sizeMd ? `md:h-${sizeMd}` : ''} ${sizeLg ? `lg:h-${sizeLg}` : ''} ${
        sizeXl ? `xl:h-${sizeXl}` : ''
      }`}
    />
  )
}
