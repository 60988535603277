import * as React from 'react'
import { motion } from 'framer-motion'

const spinTransition = {
  loop: Infinity,
  ease: 'linear',
  duration: 1,
}

export default function CircleLoader() {
  return (
    <div className="box-border w-10 h-10 relative">
      <motion.span
        className="block w-10 h-10 box-border absolute  top-0 left-0 border-white border rounded-full"
        style={{ borderTopColor: '#031F3C', borderWidth: 6 }}
        animate={{ rotate: 360 }}
        transition={spinTransition}
      />
    </div>
  )
}
