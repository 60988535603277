import * as React from 'react'

import Spacer from '../Spacer'
import Button from '../Button'
import Text from '../Text'

import background from './footer.svg'
import portrait from './portrait.jpg'

type ContactInfoProps = {
  name: string
  children: React.ReactNode
  href?: string
}

function ContactInfo({ name, children, href }: ContactInfoProps) {
  return (
    <div className="flex-1 flex">
      <Text styleName="w-16 font-medium">{name}</Text>
      <Text>
        <a className="flex-1 cursor-pointer font-light" href={href}>
          {children}
        </a>
      </Text>
    </div>
  )
}

export default function Footer() {
  return (
    <footer
      className="h-64 flex flex-1 items-center p-4 pt-16 md:p-8 md:pt-24 lg:p-16 lg:pt-32 xl:p-32 xl:pt-32 bg-cover  relative"
      style={{ backgroundImage: `url(${background})` }}>
      <div className="absolute top-0 left-0 transform -translate-y-1/2 translate-x-4 md:translate-x-8 lg:translate-x-16 xl:translate-x-32">
        <div
          className="rounded-full shadow-xl w-32 h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 xl:w-64 xl:h-64 bg-cover "
          style={{ backgroundImage: `url(${portrait})`, backgroundPosition: '90% 80%' }}
        />
      </div>
      <div className="flex flex-col">
        <Spacer size={8} />
        <h1 className="text-regal-blue text-2xl md:text-3xl lg:text-4xl font-medium uppercase">Thomas Pohlan</h1>
        <span className="text-regal-blue text-md md:text-lg lg:text-xl font-medium">Gutachter und Geschäftsführer</span>
        <Spacer size={8} />
        <div className="flex flex-col text-md">
          <ContactInfo name="Mail:" href="mailto:info@bauwerksdiagnostik-dresden.de">
            info@bauwerksdiagnostik-dresden.de
          </ContactInfo>
          <ContactInfo name="Tel.:" href="mailto:info@bauwerksdiagnostik-dresden.de">
            0351 33 28 81 49{' '}
          </ContactInfo>
          <ContactInfo name="Mobil:" href="tel:0172123456789">
            0173 51 77 601
          </ContactInfo>
        </div>
        <Spacer size={8} />
        <Button>Schreiben Sie mir!</Button>
      </div>
    </footer>
  )
}
