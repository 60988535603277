import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as H from 'history'

import { ReactComponent as Circle } from './circle.svg'

type S = H.LocationState

type Props = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>)
  children: React.ReactNode
  expanded: boolean
}

export default function NavigationLink({ to, children, expanded = true }: Props) {
  let location = useLocation()

  return (
    <div>
      <Link to={to}>
        <div
          className={`text-white hover:bg-bronze transition-colors duration-150 ease-in-out cursor pointer ${
            location.pathname === to ? 'bg-bronze' : 'bg-regal-blue'
          }`}>
          <div className="flex pl-3 py-2 items-center">
            <div className="h-10 w-10 flex items-center justify-center">
              <Circle />
            </div>
            <div
              className={`flex-1 pl-4 text-xl transition-all duration-250 ease-in-out ${
                expanded ? 'block' : 'hidden'
              }`}>
              {children}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
