import * as React from 'react'

type Props = {
  children: React.ReactNode
  styleName?: string
}

export default function Headline({ children, styleName }: Props) {
  return (
    <div className={`headline pb-6 ${styleName}`}>
      <h1 className="text-regal-blue text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-medium">{children}</h1>
      <div className="bg-bronze h-1 w-16 mt-2" />
    </div>
  )
}
