import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as H from 'history'

type S = H.LocationState

type Props = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>)
  children: React.ReactNode
  expanded: boolean
}

export default function NavigationLink({ to, children, expanded = true }: Props) {
  let location = useLocation()

  if (!expanded) {
    return null
  }

  return (
    <div>
      <Link to={to}>
        <div
          className={`flex pl-3 py-2 items-center text-white hover:bg-bronze transition-colors duration-150 ease-in-out cursor pointer ${
            location.pathname === to ? 'bg-bronze' : 'bg-regal-blue'
          }`}>
          <div className="flex-1 pl-4 text-lg transition-all duration-250 ease-in-out block font-light">{children}</div>
        </div>
      </Link>
    </div>
  )
}
