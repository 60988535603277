import * as React from 'react'

type Props = {
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export default function Button({ children, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      className="bg-bronze text-white flex justify-center items-center h-12 text-lg w-48 cursor-pointer max-w-full">
      {children}
    </div>
  )
}
