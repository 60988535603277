import * as React from 'react'

import Spacer from '../Spacer'
import Text from '../Text'

type Props = {
  img: string
  alt?: string
  title: string
  styleName?: string
}

export default function Teaser({ img, alt, title, styleName }: Props) {
  return (
    <div className={`flex-full sm:flex-1/2 md:flex-1/3 lg:flex-1/4 xl:flex-1 p-4 flex flex-col ${styleName}`}>
      <div className="flex-1">
        <img src={img} alt={alt ? alt : title} loading="lazy" />
      </div>
      <Spacer size={4} />
      <Text>{title}</Text>
    </div>
  )
}
