import * as React from 'react'
import circle from './circle.svg'

import { ReactComponent as Logo } from './logo.svg'

export default function Header() {
  return (
    <header className="relative">
      <div className="py-2 sm:py-4 md:py-8 lg:py-16 px-4 md:px-8 lg:px-12 xl:px-32">
        <Logo title="Thomas Pohlan Bauwerksdiagnostik" className="max-w-full md:pr-32 lg:pr-0" />
      </div>
      <div className="absolute transform hidden md:block md:scale-75  xl:scale-90 translate-x-1/2 -translate-y-1/2 top-0 right-0">
        <img src={circle} alt="Thomas Pohlan Bauwerksdiagnostik" loading="lazy" />
      </div>
    </header>
  )
}
