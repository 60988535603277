import * as React from 'react'
import NavigationLink from './NavigationLink'
import Link from './Link'

import { ReactComponent as MenuIcon } from './menu.svg'

type Props = {
  onNavigationToggle: () => void
  expanded: boolean
}

export default function Navigation({ onNavigationToggle, expanded }: Props) {
  return (
    <nav
      id="aside"
      className={`fixed min-h-screen z-20 bg-regal-blue flex flex-col transition-all duration-200 ease-in-out ${
        expanded ? 'w-56' : 'w-16'
      }`}>
      <div className={`flex justify-end py-4 ${expanded ? 'px-6' : 'px-2'}`}>
        <div
          className={`flex h-10 items-center justify-center w-12 transform ${expanded ? 'rotate-0' : 'rotate-180'}`}
          onClick={onNavigationToggle}>
          <MenuIcon className="cursor-pointer" />
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <NavigationLink to="/" expanded={expanded}>
          Startseite
        </NavigationLink>
        <NavigationLink to="/leistungen" expanded={expanded}>
          Leistungen
        </NavigationLink>
        <NavigationLink to="/referenzen" expanded={expanded}>
          Referenzen
        </NavigationLink>
        <NavigationLink to="/foerdermittel" expanded={expanded}>
          Fördermittel
        </NavigationLink>
        <NavigationLink to="/gutachten" expanded={expanded}>
          Gutachten
        </NavigationLink>
        <NavigationLink to="/kontakt" expanded={expanded}>
          Kontakt
        </NavigationLink>
      </div>
      <div className="flex flex-col py-4">
        <Link to="/datenschutz" expanded={expanded}>
          Datenschutz
        </Link>
        <Link to="/impressum" expanded={expanded}>
          Impressum
        </Link>
      </div>
    </nav>
  )
}
