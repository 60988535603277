import * as React from 'react'
import { HashRouter as Router } from 'react-router-dom'

import { Navigation, Header, Footer, Spacer } from 'components'

import AppContainer from './AppContainer'

function App() {
  const [isNavigationExpanded, setNavigationExpanded] = React.useState<boolean>(window.innerWidth >= 768)

  const toggleNavigationExpanded = React.useCallback(() => setNavigationExpanded(s => !s), [])

  return (
    <div id="app" className="flex flex-col min-h-screen">
      <Router>
        <div id="wrapper flex flex-1">
          <Navigation expanded={isNavigationExpanded} onNavigationToggle={toggleNavigationExpanded} />
          <div
            id="main"
            className={`min-h-screen flex flex-col flex-1 overflow-x-hidden ${
              isNavigationExpanded ? 'md:pl-56 pl-16' : 'pl-16'
            }`}>
            <Header />
            <AppContainer />
            <Spacer size={40} />
            <Footer />
          </div>
        </div>
      </Router>
    </div>
  )
}

export default App
