import * as React from 'react'

import Text from '../Text'

type Props = {
  img: string
  alt?: string
  title: string
  className?: string
  open?: boolean
}

export default function Collapsible({ img, alt, title, className = '', open = false }: Props) {
  const [isOpen, setIsOpen] = React.useState<boolean>(() => open)
  return (
    <section className={className}>
      <div className="flex items-center" onClick={() => setIsOpen(open => !open)}>
        <a href="/" className="font-semibold cursor-pointer">
          {!isOpen ? (
            <svg viewBox="0 0 20 20" fill="currentColor" className="chevron-right w-8 h-8">
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"></path>
            </svg>
          ) : (
            <svg viewBox="0 0 20 20" fill="currentColor" className="chevron-down w-8 h-8">
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
          )}
        </a>
        <Text styleName="lg:text-lg xl:text-xl cursor-pointer font-normal">{title}</Text>
      </div>
      <div
        className="transition-all duration-200"
        style={{
          maxHeight: isOpen ? 'inherit' : '0px',
          opacity: isOpen ? '1' : '0',
          pointerEvents: isOpen ? 'all' : 'none',
        }}>
        <div className="w-full md:w-3/4 lg:w-1/2 xl:w-1/3 py-6">
          <a href={img} target="_blank" rel="noopener noreferrer">
            <img src={img} alt={alt ? alt : title} />
          </a>
        </div>
      </div>
    </section>
  )
}
